@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HungHau';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(./assets/fonts/HungHau-Regular.ttf) format("ttf");
}

html {
  font-family: "HungHau"
}


.slide-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
  gap: 20px;
}

.slide-item-container {
  display: flex;
}

.slide-item {
  scroll-snap-align: start;
  flex: 0 0 auto; /* Giữ kích thước cố định cho các slide */
  width: 300px; /* Điều chỉnh kích thước cho mỗi bài viết */
  margin-right: 10px;
}
