/* .cardNews:hover > a > img {
  filter: brightness(50%);
} */
.cardNews:hover >a>div>p{
  text-decoration: underline;
}
.cardNews:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.skeleton-container {
  position: relative;
}

.skeleton-item {
  background-color: #f2f2f2;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.6;
  }
}