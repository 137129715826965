.cardNewsCategories:hover > a > img {
    filter: brightness(50%);
}
.cardNewsTop:hover >a>div>p{
    text-decoration: underline;
}

#form {
    transition: transform 0.3s ease;
}


#form.hidden {
    transform: translate(-50%, 100%); /* Di chuyển form xuống dưới màn hình */
}


body::-webkit-scrollbar{
    max-width: 7px;
  }
   
  body::-webkit-scrollbar-track{
    background-color: #ecf7ff;
  }
   
  body::-webkit-scrollbar-thumb{
    /* background-image: linear-gradient(-45deg, #0F7BBE, #4AAA48); */
    background-color: #4AAA48;
    border-radius: 50px;
  }