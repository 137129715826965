.styleS figure {
    max-width: 50%;
    height: auto;
    margin: auto;
  }
  
.styleS figure div img {
  border-radius: 10px;
  display: block;
  justify-content: center;
  margin: auto;
}

@media (max-width: 768px) {
  .styleS figure {
    max-width: 90%;
    height: auto;
    margin: auto;
  }

  .styleS figure div img {
    border-radius: 10px;
    display: block;
    justify-content: center;
    margin: auto;
  }
}